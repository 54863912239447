'use strict';

/**
 * Code
 * -------------------------------------------------------------------
 */


jQuery(document).ready(function() {

/*_____________________ SLIDER ____________________-*/

if(jQuery('.module-slider-main-1').length){
    jQuery(".module-slider-main-1 .kopa-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots:false,
        autoplay:false,
        slideSpeed: 3000,
        responsive:[
                {
                  breakpoint: 639,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
            ],
    });
}

/*_____________________ SLIDER ____________________-*/

if(jQuery('.module-slider-main-2').length){
    jQuery(".module-slider-main-2 .kopa-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots:false,
        autoplay:false,
        slideSpeed: 3000,
        responsive:[
                {
                  breakpoint: 639,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
            ],
    });
}


if(jQuery('.module-slider-1').length){
    jQuery(".module-slider-1 .kopa-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots:true,
        infinite: true,
        responsive:[
                {
                  breakpoint: 1160,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },

                {
                  breakpoint: 639,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
            ],

    });
}

if(jQuery('.module-slider-2').length){

    jQuery(".module-slider-2 .kopa-slider-for").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.module-slider-2 .kopa-slider-nav'
    });

    jQuery(".module-slider-2 .kopa-slider-nav").slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.module-slider-2 .kopa-slider-for',
        arrows:false,
        dots:false,
        centerMode: true,
        centerPadding:'0px',
        focusOnSelect: true,
    });
}

if(jQuery('.module-slider-3').length){
    jQuery(".module-slider-3 .kopa-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:"<span class='slick-prev ti-angle-left'></span>",
        nextArrow:"<span class='slick-next ti-angle-right'></span>",
        dots:false,
        infinite: true,
        responsive:[
                {
                  breakpoint: 639,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
            ],
    });
}

if(jQuery('.module-slider-4').length){
    jQuery(".module-slider-4 .kopa-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots:true,
        infinite: true,
    });
    jQuery(".module-slider-4 .fa-share-alt").each(function(event){
        jQuery(this).on("click",function(event){
            event.preventDefault();
            jQuery(this).closest('figure').find('.kopa-social-icon').toggleClass('show');
        });
    });
}

if(jQuery('.module-slider-5').length){

    jQuery(".module-slider-5 .kopa-slider-for").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.module-slider-5 .kopa-slider-nav',
        
    });

    jQuery(".module-slider-5 .kopa-slider-nav").slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.module-slider-5 .kopa-slider-for',
        arrows:true,
        prevArrow:"<span class='slick-prev ti-angle-left'></span>",
        nextArrow:"<span class='slick-next ti-angle-right'></span>",
        dots:false,
        centerMode: true,
        centerPadding:'0px',
        focusOnSelect: true,
        responsive:[
            {
              breakpoint: 979,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },

            {
              breakpoint: 639,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ],

    });
}

if(jQuery('.module-slider-6').length){
    jQuery(".module-slider-6 .kopa-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:"<span class='slick-prev fa fa-caret-left'></span>",
        nextArrow:"<span class='slick-next fa fa-caret-right'></span>",
        infinite: true,
        
    });
}


if(jQuery('.module-slider-7').length){
    jQuery(".module-slider-7 .kopa-slider").slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:"<span class='slick-prev ti-angle-left'></span>",
        nextArrow:"<span class='slick-next ti-angle-right'></span>",
        infinite: true,
        responsive:[
            {
              breakpoint: 1023,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },

            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },

            {
              breakpoint: 479,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ]

    });
}

if(jQuery('.module-slider-8').length){
    jQuery(".module-slider-8 .kopa-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:"<span class='slick-prev ti-angle-left'></span>",
        nextArrow:"<span class='slick-next ti-angle-right'></span>",
        infinite: true,

        responsive:[
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ]
    });
}

if(jQuery('.module-slider-9').length){
    jQuery(".module-slider-9 .kopa-slider").slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        prevArrow:"<span class='slick-prev ti-angle-left'></span>",
        nextArrow:"<span class='slick-next ti-angle-right'></span>",
        infinite: true,

        responsive:[
            {
              breakpoint: 1023,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
        ]
    });
}


if(jQuery('.kopa-tab .kopa-slider').length){
    jQuery('.kopa-tab .kopa-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots:true,
        infinite: true,
    });
}

if(jQuery('.kopa-page-blog .entry-item .kopa-slider').length){
    jQuery('.kopa-page-blog .entry-item .kopa-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:"<span class='slick-prev fa fa-caret-left'></span>",
        nextArrow:"<span class='slick-next fa fa-caret-right'></span>",
        infinite: true,
    });
}

/*_____________ RESPONSIVE _____________*/   
    
if(jQuery('.kopa-menu-responsive').length) {
    
    jQuery('.kopa-menu-responsive').each(function(event){
        jQuery(this).find(".active-menu").on("click",function(event){
            jQuery(this).closest(".kopa-page-header").find(".kopa-header-top").fadeOut();
            jQuery(this).closest(".kopa-page-header").find(".kopa-header-middle").fadeOut();
            jQuery(this).closest(".kopa-page-header").find(".kopa-hambuger-header").fadeIn();
        });
    });
}

if(jQuery('.kopa-hambuger-header').length) {
    jQuery('.kopa-hambuger-header .kopa-hambuger-header-close').on('click',function(event){
        jQuery('.kopa-hambuger-header').fadeOut('slow');
        jQuery(this).closest(".kopa-page-header.kopa-page-header-1").find(".kopa-header-top").fadeIn('slow');
        jQuery(this).closest(".kopa-page-header").find(".kopa-header-middle").fadeIn('slow');
    });
    jQuery('.kopa-hambuger-header ul').navgoco({
        accordion: true
    });
}

if(jQuery('.kopa-search-header').length){
    jQuery('.kopa-search-header').each(function(event){
        jQuery(this).find('.ti-search').on('click',function(event){
            jQuery('.kopa-search-header form').slideToggle();
        })
    })
}


/*______________________ COUNT UP __________________*/

if (jQuery('.counter').length) {
    jQuery('.counter').each(function(event) {
        jQuery(this).counterUp({
            time: 3000,
            delay: 10
        });
    });
}

/*____________ COUNT DOWN __________________*/


if ($('.kopa-countdown').length > 0) {
            
      var nextYear = new Date(new Date().getFullYear() + 1, 0, 0, 0, 0, 0, 0);

      $('.kopa-countdown.style-01').countdown('2017/07/26 10:25:00', function(event) {
        var $this = $(this).html(event.strftime(''
            +'<li class="d"><span>days</span><h3>%D</h3><span class="year">2016</span></li>'
            +'<li class="h"><span>hours</span><h3>%H</h3><span class="year">2016</span></li>'
            +'<li class="m"><span>minutes</span><h3>%M</h3><span class="year">2016</span></li>'
            +'<li class="s"><span>seconds</span><h3>%S</h3><span class="year">2016</span></li>'));
      });

      $('.kopa-countdown.style-02').countdown('2017/07/26 10:25:00', function(event) {
        var $this = $(this).html(event.strftime(''
            +'<li class="d"><h3>%D</h3><span>days</span></li>'
            +'<li class="h"><h3>%H</h3><span>hours</span></li>'
            +'<li class="m"><h3>%M</h3><span>minutes</span></li>'
            +'<li class="s"><h3>%S</h3><span>seconds</span></li>'));
      });

      $('.kopa-countdown.style-03').countdown('2017/07/26 10:25:00', function(event) {
        var $this = $(this).html(event.strftime(''
            +'<li class="d"><h3>%D</h3><span>days</span></li>'
            +'<li class="h"><h3>%H</h3><span>hours</span></li>'
            +'<li class="m"><h3>%M</h3><span>mins</span></li>'
            +'<li class="s"><h3>%S</h3><span>secs</span></li>'));
      });
}

/*__________________ LOADING BAR ____________________*/

var waypoint_element_1 = jQuery(".kopa-loading-bar");

if (('.kopa-loading-bar').length) {
    waypoint_element_1.waypoint({
        handler: function() {
            jQuery('.kopa-loading-bar .kopa-loading-body').each(function() {

                var get_data = jQuery(this).attr('data-load');
                var set_data = get_data;

                jQuery(this).animate({
                    width: set_data + "%"
                }, 2000, 'swing', function() {
                    jQuery(".kopa-loading-bar .data-load").fadeIn("slow");
                });
            });
        },
        offset: 300
    });
}

/*_____________ MENU SUPERFISH _____________*/

if(jQuery(".kopa-main-menu").length){
    jQuery(".kopa-main-menu").superfish({
        speed: 'normal',
        delay: '600'
    });
}



/*_____________ Scroll top Top _____________*/   
    
    if(jQuery('.kopa-scroll-up').length){
        jQuery(window).scroll(function(event){
            var x = jQuery(window).scrollTop();
            if(x > 200){
                jQuery(".kopa-scroll-up").fadeIn("slow");
            }
            else{
                jQuery(".kopa-scroll-up").fadeOut();
            }
        });
        jQuery(".kopa-scroll-up").on('click',function(event){
            jQuery("html,body").animate({scrollTop:0},800);
        })
    }


/*_____________ MatchHeight _____________*/

    function mh_1(){
        jQuery('.ul-mh').children().matchHeight();
    }
    if(jQuery('.ul-mh').length){
        mh_1();
    }

/*_____________ Scroll Animation _____________*/

    var wow = new WOW();
    wow.init();

/*_____________ ACCORDION _____________*/

    var i1 = 0;
    if(jQuery('.kopa-accordion').length){    
        jQuery('.kopa-accordion').each(function() {
            var ct_acc_1 = jQuery(this).find('.panel-group');
            ct_acc_1.each(function() {
                var item_index_1 = 'accordion' + i1,
                    item_index_11 = '#' + item_index_1;

                jQuery(this).attr('id',item_index_1);
                jQuery(this).find('.panel-default').each(function() {
                    jQuery(this).find('.panel-title').children('a').attr('data-parent', item_index_11);
                    jQuery(this).find('.panel-title').children('a').attr('href', item_index_11 + + jQuery(this).index());
                    jQuery(this).find('.panel-collapse').attr('id', item_index_11 + + jQuery(this).index());
                });
            });
            ++i1;
        }); 
    }

    var panel_titles = jQuery('.kopa-accordion .panel-title a');
    panel_titles.addClass("collapsed");
    jQuery('.panel-heading.active').find(panel_titles).removeClass("collapsed");

    panel_titles.on('click',function(){

        jQuery(this).closest('.panel').find('.panel-heading').toggleClass('active');
        jQuery(this).closest('.panel').find('.collapse').slideToggle();
    });

    /*__________________ MASONRY ______________________*/

    var ms_1 = jQuery('.kopa-masonry');

    if (jQuery(ms_1).length) {
    
        jQuery(ms_1).each(function(){

            var container_1 = jQuery(this).find(".kopa-masonry-wrap"),
                m_filters_1 = jQuery('.kopa-masonry-header').find('li');
            var masonryOptions = {
                columnWidth: 1,
                itemSelector : '.kopa-masonry-wrap-item.show'
            };
            
            container_1.imagesLoaded(function(event){

                var m_grid = container_1.masonry( masonryOptions);

                m_filters_1.on("click",function(event){

                    event.preventDefault();
                    m_filters_1.removeClass('active');
                    jQuery(this).addClass('active');
                    var m_filter_val = jQuery(this).data('val');

                    container_1.find('.kopa-masonry-wrap-item').each(function(){
                        var m_item_val = jQuery(this).data('val').toString().split(',');                
                        var a = m_item_val.indexOf(m_filter_val.toString()) > -1;

                        if (m_filter_val === "*") {
                            jQuery(this).removeClass('hide');
                            jQuery(this).addClass('show');
                        } else {
                            if ( a == true) {
                                jQuery(this).removeClass('hide');
                                jQuery(this).addClass('show');  
                            } else {
                                jQuery(this).removeClass('show');
                                jQuery(this).addClass('hide');
                            }
                        }                               
                    });

                    container_1.masonry('layout');

                });

            });

        });
    }

    /*____________________ Clear Event Link __________________*/
 
    if (('.clear-href').length) {
        jQuery('a.clear-href').on('click', function(event) {
            event.preventDefault();
            //code
        });
    }

    /*_____________ LOADMORE _____________*/


    var link_to_github_1 = "https://gist.githubusercontent.com/anonymous/0d508fed17ad25a45996b502e6b26779/raw/f5ba36cae3627f9381a7086152c52704db47f36f/gistfile1.txt",
        link_to_github_2 = "https://gist.githubusercontent.com/anonymous/91b00e3b298b3c92783daea2570cb63e/raw/85fa82789dd3c87b7b0481105aeb310a3583f6bc/gistfile1.txt",
        link_to_github_3 = "https://gist.githubusercontent.com/anonymous/382cea10a2d02fc38005fb710b83678c/raw/cf615897ccda043d3cad424069beb315d2193659/gistfile1.txt",
        link_to_github_4 = "https://gist.githubusercontent.com/anonymous/01e51556b5eba3cc64712361bcdad240/raw/30e38770ce46c0159f26ed54fc22ff7131790c13/gistfile1.txt";
    jQuery('.kopa-link-loadmore').on('click',function(){
        var button_2 = jQuery(this);
        jQuery.ajax({
            url:link_to_github_1,
            beforeSend: function( xhr ) {
            },
        })

        .done(function(data) {

            jQuery(data).imagesLoaded(function() {

                var pos_ins_data_1 =  jQuery('.kopa-link-loadmore').closest(".module-present-5").find(".kopa-masonry");
                var items = jQuery(data).find('.kopa-masonry-wrap-item');
                if(items.length){
                    jQuery.each(items, function(items, index){
                        jQuery(pos_ins_data_1).append(jQuery(this));
                    });
                }

            });
        });
    });

    jQuery('.kopa-link-loadmore-1').on('click',function(){
        var button_2 = jQuery(this);
        jQuery.ajax({
            url:link_to_github_2,
            beforeSend: function( xhr ) {
            },
        })

        .done(function(data) {

            jQuery(data).imagesLoaded(function() {

                var pos_ins_data_1 =  jQuery('.kopa-link-loadmore-1').closest(".module-gallery-2").find(".kopa-masonry");
                var items = jQuery(data).find('.kopa-masonry-wrap-item');
                if(items.length){
                    jQuery.each(items, function(items, index){
                        jQuery(pos_ins_data_1).append(jQuery(this));
                    });
                }

            });
        });
    });

    jQuery('.kopa-link-loadmore-2').on('click',function(){
        var button_2 = jQuery(this);
        jQuery.ajax({
            url:link_to_github_3,
            beforeSend: function( xhr ) {
            },
        })

        .done(function(data) {

            jQuery(data).imagesLoaded(function() {

                var pos_ins_data_1 =  jQuery('.kopa-link-loadmore-2').closest(".module-gallery-3").find(".kopa-masonry");
                var items = jQuery(data).find('.kopa-masonry-wrap-item');
                if(items.length){
                    jQuery.each(items, function(items, index){
                        jQuery(pos_ins_data_1).append(jQuery(this));
                    });
                }

            });
        });
    });

    jQuery('.kopa-link-loadmore-3').on('click',function(){
        var button_2 = jQuery(this);
        jQuery.ajax({
            url:link_to_github_3,
            beforeSend: function( xhr ) {
            },
        })

        .done(function(data) {

            jQuery(data).imagesLoaded(function() {

                var pos_ins_data_1 =  jQuery('.kopa-link-loadmore-3').closest(".module-gallery-4").find(".kopa-masonry");
                var items = jQuery(data).find('.kopa-masonry-wrap-item');
                if(items.length){
                    jQuery.each(items, function(items, index){
                        jQuery(pos_ins_data_1).append(jQuery(this));
                    });
                }

            });
        });
    });

     jQuery('.kopa-link-loadmore-4').on('click',function(){
        var button_2 = jQuery(this);
        jQuery.ajax({
            url:link_to_github_4,
            beforeSend: function( xhr ) {
            },
        })

        .done(function(data) {

            jQuery(data).imagesLoaded(function() {

                var pos_ins_data_1 =  jQuery('.kopa-link-loadmore-4').closest(".kopa-page-blog").find(".kopa-masonry");
                var items = jQuery(data).find('.kopa-masonry-wrap-item');
                if(items.length){
                    jQuery.each(items, function(items, index){
                        jQuery(pos_ins_data_1).append(jQuery(this));
                    });
                }

            });
        });
    });

    /*_____________ GOOGLE MAP _____________*/

    if (jQuery('.kopa-map').length > 0) {

        var id_map = jQuery('.kopa-map').attr('id');
        var lat = parseFloat(jQuery('.kopa-map').attr('data-latitude'));
        var lng = parseFloat(jQuery('.kopa-map').attr('data-longitude'));
        var place = jQuery('.kopa-map').attr('data-place');
        var iconBase = '../../../assets/images/icons/logo-map.png';

        var map = new GMaps({
            el: '#'+id_map,
            lat: lat,
            lng: lng,
            zoom: 19,
            zoomControl : true,
            zoomControlOpt: {
              style : 'SMALL',
              position: 'TOP_RIGHT'
            },
            panControl : false,
            streetViewControl : false,
            mapTypeControl: false,
            overviewMapControl: false,
            scrollwheel: false,
            styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#e9e4e1"},{"visibility":"on"}]},{"featureType":"landscape","elementType":"labels.text","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"labels.text.fill","stylers":[{"color":"#797979"}]},{"featureType":"landscape","elementType":"labels.text.stroke","stylers":[{"color":"#e9e4e1"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45},{"visibility":"on"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"color":"#d8cdcd"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.highway","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.highway.controlled_access","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.highway.controlled_access","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"transit.line","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"transit.station","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#f6f5f1"},{"visibility":"on"}]}]
        });
        map.addMarker({
            lat: lat,
            lng: lng,
            title: place,
            icon: iconBase 
        });

    }

    /*______________________________ booking ultra _____________________-*/

    if(jQuery('.hasDatepicker').length){
        jQuery('.hasDatepicker').on('click',function(){
            jQuery('#ui-datepicker-div').toggle();
        });
    }

    /*_____________________ show video ______________________*/

    if(jQuery('.kopa-video').length){
        jQuery('.play-video').each(function(event){
            jQuery(this).on('click',function(event){
                event.preventDefault();
                jQuery('.kopa-video').fadeIn();
            });
        });
        jQuery(this).find('.close').on('click',function(event){
            jQuery('.kopa-video').fadeOut();

        });
    }

    /*________________________- Switch tab to accordion ___________________-*/

    var isMobile = window.matchMedia("only screen and (max-width: 359px)");

    if (isMobile.matches) {
       if(jQuery('.module-department-1').length){
           jQuery('.module-department-1 .kopa-tab .kopa-header-tab li a').each(function(event){
                jQuery(this).on('click',function(event){
                    var data_content = jQuery(this).attr('data-content');
                    var get_content_tab = jQuery(".module-department-1 .kopa-tab .tab-content div[data-content=" + data_content + "]").html();
                    jQuery(this).closest('li').find('.accordion-content').html(get_content_tab);
                    jQuery('.module-department-1 .kopa-tab .kopa-header-tab li .accordion-content').slideUp();
                    jQuery(this).closest('li').find('.accordion-content').slideDown();

                })
           });
        }
    }


});